export const baseProps = {
  id: {
    type: [String, Number],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Start typing to search...',
  },
  /*
   *  Function that accepts search string and
   *  returns items to show.
   *
   *  SearchFunc(text, limit): Array<Result>
   *   - text: text to search
   *   - limit: result size
   *  Result: search result {text, id, data}
   *   - text: search result label
   *   - id: uuid of found result, optional
   *   - data: any additional data to use in results, optional
   */
  searchFunc: {
    type: Function,
    default: (t,l) => { throw `getSearchResults(${t}, ${l}) NOT IMPLEMENTED`; },
  },
  closeAfterSelecting: {
    type: Boolean,
    default: true,
  },
  highlight: {
    type: Boolean,
    default: true,
  },
  iconSize: {
    type: Number,
    default: 32,
  },
  btnSize: {
    type: Number,
    default: 24,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  pageSize: {
    type: Number,
    default: 50,
  },
  searchIconColor: {
    type: String,
    default: 'var(--colour-utility-action)'
  },
  closeIconColor: {
    type: String,
    default: 'var(--colour-utility-base)'
  },
  openOnLoad: {
    type: Boolean,
    default: false
  },
  searchOnLoad: {
    type: Boolean,
    default: true
  },
  showAllResults: {
    type: Boolean,
    default: false // Show all results when input cleared
  },
};

export const baseEmits = ['input', 'search', 'blur', 'focus', 'valid', 'open', 'close', 'loadMore'];