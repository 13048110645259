<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div class="email-editor">
    <div>
      <Field
        id="subject"
        v-model="subject"
        type="text"
        :placeholder="t('Subject')"
        :max-length="40"
        :disabled="isSending || !!props.threadToReply"
        twoway
      />
      <trix-editor
        :id="`trixy-${contact.id}`"
        ref="editorRef"
        class="trix-editor"
      />
    </div>
    <div class="actions">
      <CustomButton
        purpose="text"
        :label="t('Discard')"
        :disabled="isSending"
        @click.stop="closeComposeView"
      />
      <CustomButton
        purpose="action"
        :label="t('Send')"
        :disabled="isSending || message.trim() === ''"
        small
        @click.stop="sendComposedEmail"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, watch, computed } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import { t, sendMail } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
// eslint-disable-next-line no-unused-vars
import Trix from 'trix';

const props = defineProps({
  contact: {
    type: Object,
    required: true,
  },
  threadToReply: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close', 'success']);

const editorRef = ref(null);
const subject = ref(null);
const message = ref('');
const attachments = ref([]);
const isSending = ref(false);

const conversation = computed(() => props.threadToReply?.conversation);

const reSubject = computed(() => 
  (conversation.value) 
    ? ((conversation.value.subject || '').toLowerCase().includes('re:')
      ? conversation.value.subject
      : `Re: ${conversation.value.subject}`)
    :'');

watch(
  props,
  () => {
    if (conversation.value) {
      subject.value = reSubject.value;
    }
  },
  { immediate: true }
);

const closeComposeView = () => {
  emit('close');
};

const sendComposedEmail = async () => {
  isSending.value = true;
  const trix = editorRef.value;
  trix.setAttribute('contenteditable', 'false');
  let body = trix.innerHTML;

  await sendMail({
    customer_id: props.contact.relationship.id,
    to: props.contact.id,
    subject: subject.value,
    body,
    attachments: attachments.value,
    reply_id: props.threadToReply?.reply_id || '',
  });

  isSending.value = false;
  trix.setAttribute('contenteditable', 'true');
  emit('success');
  closeComposeView();
};

function trixContentUpdate(event) {
  if (event.target.id === editorRef.value.id) {
    const trix = editorRef.value;
    const body = trix.editor.getDocument();
    message.value = body.toString();
  }
}

function trixAttachmentAdd(event) {
  if (event.target.id === editorRef.value.id) {
    if (event.attachment.file) {
      let file = event.attachment.file;

      if (file.type.startsWith('image/')) {
        let reader = new FileReader();

        reader.onload = function (e) {
          let base64 = e.target.result;
          let image = `<img src="${base64}" />`;
          let editor = editorRef.value.editor;
          let attachmentIndex = editor.getPosition();
          editor.setSelectedRange([attachmentIndex - 1, attachmentIndex]);
          editor.deleteInDirection('backward');
          editor.insertHTML(image);
        };

        reader.readAsDataURL(file);
      } else {
        let reader = new FileReader();

        reader.onload = function (e) {
          let content = arrayBufferToBase64(e.target.result);
          attachments.value.push({
            content_type: file.type,
            filename: file.name,
            content,
          });
        };

        reader.readAsArrayBuffer(file);
      }
    }
  }
}

function trixAttachmentRemove(event) {
  if (event.target.id === editorRef.value.id) {
    const filename = event.attachment?.file?.name;
    attachments.value = attachments.value.filter(f => f.filename !== filename);
  }
}

function arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

onMounted(() => {
  document.addEventListener('trix-change', trixContentUpdate);
  document.addEventListener('trix-attachment-add', trixAttachmentAdd);
  document.addEventListener('trix-attachment-remove', trixAttachmentRemove);
});

onUnmounted(() => {
  document.removeEventListener('trix-change', trixContentUpdate);
  document.removeEventListener('trix-attachment-add', trixAttachmentAdd);
  document.removeEventListener('trix-attachment-remove', trixAttachmentRemove);
});
</script>

<style lang="scss">
trix-toolbar .trix-button-row {
  flex-wrap: wrap; // Stops the toolbar from forcing the container wider than the viewport and obscuring content
  column-gap: var(--spacing-2);
}
trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: 0;
}
</style>

<style lang="scss" scoped>
.email-editor {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}
.actions {
  display: flex;
  gap: var(--spacing-2);
  justify-content: flex-end;
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.message-expand-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.trix-editor {
  overflow: scroll;
  max-height: 400px;
}

.trix-editor:deep(ul),
.trix-editor:deep(ol) {
  list-style-position: inside;
}

.trix-editor:deep(blockquote) {
  border: 0 solid var(--colour-panel-g-24);
  border-left-width: 0.3em;
  margin-left: 0.3em;
  padding-left: 0.6em;
}

.trix-editor:deep(pre) {
  vertical-align: top;
  font-family: monospace;
  font-size: 0.9em;
  padding: 0.5em;
  white-space: pre;
  background-color: var(--colour-panel-g-8);
  overflow-x: auto;
}
</style>
