import { computed } from 'vue';
import { t } from '@sales-i/utils';
import {
  CLEAR_ALL,
  DELETE_BY_ID,
  GET_ALL,
  GET_SUMMARY,
  SET_CONFIRMATION_MODAL,
  SET_ROUTE,
} from '@/shared/store/actionType';
import { useRoute } from 'vue-router';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { useStore } from 'vuex';
import { scrollIntoView } from '@/shared/directives';
import { opportunities as opps, missed_potential_opportunities } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { navigateToUrl } from 'single-spa';

export const useOpportunity = ({ store = useStore(), emit, vroute = useRoute()} = {}) => {
  const { getPermissions } = usePermissions();
  
  const permissions = getPermissions(opps);
  const aiPermissions = getPermissions(missed_potential_opportunities);
  const opportunities = computed(() => store.state.crm.opportunities.all);
  const opportunityItems = computed(() => store.state.crm.opportunities.all.data);
  const selected = computed(() => store.state.crm.opportunities.selected.data);
  const selectedLoaded = computed(() => store.state.crm.opportunities.selected.loaded);
  const opportunitiesLoading = computed(() => store.state.crm.opportunities.all.loading);
  const opportunitiesLoaded = computed(() => store.state.crm.opportunities.all.loaded);
  const opportunitiesSummary = computed(() => store.state.crm.opportunities.summary.data);
  const sortOptions = computed(() => store.state.crm.opportunities.sorting);

  // AI Opportunities === using opportunities endpoint `opportunities/with-products`
  const aiOpportunityItems = computed(() => store.state.crm.opportunityProducts.all.data);
  const aiOpportunitiesLoading = computed(() => store.state.crm.opportunityProducts.all.loading);
  const aiOpportunitiesLoaded = computed(() => store.state.crm.opportunityProducts.all.loaded);
  const aiOpportunitiesSortOptions = computed(() => store.state.crm.opportunityProducts.sorting);

  const setRoute = params => store.dispatch(`system/${SET_ROUTE}`, params);
  const getOpportunities = params => store.dispatch(`crm/opportunities/${GET_ALL}`, params);
  const clearOpportunities = () => store.dispatch(`crm/opportunities/${CLEAR_ALL}`);
  const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
  const deleteOpportunity = params => store.dispatch(`crm/opportunities/${DELETE_BY_ID}`, params);
  const getSummary = params => store.dispatch(`crm/opportunities/${GET_SUMMARY}`, params);
  const getOpportunitiesWithProducts = params => store.dispatch(`crm/opportunityProducts/${GET_ALL}`, params);
  const clearOpportunitiesWithProducts = () => store.dispatch(`crm/opportunityProducts/${CLEAR_ALL}`);
  const deleteOpportunityWithProducts = params => store.dispatch(`crm/opportunityProducts/${DELETE_BY_ID}`, params);
  const clearAiOpportunities = () => store.dispatch(`crm/opportunityProducts/${CLEAR_ALL}`);

  function showDeleteOpportunityModal(item) {
    showConfirmationModal({
      message: t(
        `Do you really want to delete Opportunity #${item.id}`,
        'do_you_really_want_to_delete_opportunity_id',
        { interpolations: {id: item.id}}
      ),
      updatedMessage: t(
        `You have deleted Opportunity #${item.id}`,
        'you_have_deleted_opportunity_id',
        { interpolations: {id: item.id} }
      ),
      updateFunction: () => confirmDelete(item),
      finishFunction: finishDelete,
    });
  }

  async function confirmDelete(item) {
    try {
      await deleteOpportunity(item.id);
      emit('opportunityDeleted', item);
      return selectedLoaded.value;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  function finishDelete() {
    emit('closeModal');
    return true;
  }

  function openOpportunityDetails(item, openInNewTab=false) {
    if (openInNewTab) {
      let path = `${baseUrl}/${opportunitiesArea}/${item.id}`;
      let query = new URLSearchParams(vroute.query);
      window.open(`${path}?${query.toString()}`, '_blank');
    } else {
      navigateToUrl(`${baseUrl}/${opportunitiesArea}/${item.id}`);
      scrollIntoView();
    }
  }

  function editOpportunity(
    item, 
    doSetRoute = false, 
    success = `${baseUrl}/${opportunitiesArea}/${item.id}`, 
    cancel = vroute.fullPath
  ) 
  {
    if (doSetRoute) setRoute({ success, cancel });
    navigateToUrl(`${baseUrl}/${opportunitiesArea}/${item.id}/edit`);
  }

  const getRolledUpValue = (item) => {
    let value = 0;
    const productsLength = item.products.length;
    for (let x = 0; x < productsLength; x++) {
      value += item.products[x].value;
    }
    return value;
  };

  /** helpers */
  const sortedOpportunitiesByDecisionDate  = computed(() => {
    const mappedOpps = (opportunities.value.data || []).map(opp => 
      ('products' in opp) ? {
        ...opp,
        value: getRolledUpValue(opp),
      } : opp
    );
    return mappedOpps.sort((a, b) => {
      if (a.decision_date > b.decision_date) {
        return -1;
      } else if (b.decision_date > a.decision_date) {
        return 1;
      }
      return 0;
    });
  });

  const opportunityHeadings = [
    {
      text: t('Assigned to'),
      value: 'allocated_users',
    },
    {
      text: t('Customer/Prospect Name'),
      value: 'entity_name',
    },
    {
      text: t('Decision Date'),
      value: 'decision_date',
    },
    {
      text: t('Value'),
      value: 'value',
    },
    {
      text: t('Probability'),
      value: 'probability',
    },
    {
      text: t('Tags'),
      value: 'tags',
    },
    {
      text: '',
      value: 'menu_links',
    },
  ];
  
  const opportunityHeadingsCustomerView = [
    {
      text: t('Assigned to'),
      value: 'allocated_users',
    },
    {
      text: t('Next steps'),
      value: 'next_step',
    },
    {
      text: t('Decision Date'),
      value: 'decision_date',
    },
    {
      text: t('Value'),
      value: 'value',
    },
    {
      text: t('Probability'),
      value: 'probability',
    },
    {
      text: t('Tags'),
      value: 'tags',
    },
    {
      text: '',
      value: 'menu_links',
    },
  ];
  
  const aiOpportunityHeadingsCustomerView = [
    {
      text: t('Assigned to'),
      value: 'allocated_users',
    },
    // WHAT HEADER HERE?
    {
      text: t('Next steps'),
      value: 'ai_next_step',
    },
    {
      text: t('Decision Date'),
      value: 'decision_date',
    },
    {
      text: t('Value'),
      value: 'value',
    },
    {
      text: '',
      value: 'menu_links',
    },
  ];
  
  const aiOpportunityHeadings = [
    {
      text: t('Assigned to'),
      value: 'allocated_users',
    },
    {
      text: t('Customer/Prospect Name'),
      value: 'entity_name',
    },
    {
      text: t('Opportunity'),
      value: 'ai_next_step',
    },
    {
      text: t('Decision Date'),
      value: 'decision_date',
    },
    {
      text: t('Value'),
      value: 'value',
    },
    {
      text: '',
      value: 'menu_links',
    },
  ];

  return {
    aiPermissions,
    permissions,
    selected,
    opportunities,
    opportunityItems,
    selectedLoaded,
    opportunitiesLoading,
    opportunitiesLoaded,
    opportunitiesSummary,
    sortedOpportunitiesByDecisionDate,
    sortOptions,
    aiOpportunityItems,
    aiOpportunitiesLoading,
    aiOpportunitiesLoaded,
    aiOpportunitiesSortOptions,
    showConfirmationModal,
    deleteOpportunity,
    showDeleteOpportunityModal,
    openOpportunityDetails,
    editOpportunity,
    confirmDelete,
    finishDelete,
    getOpportunities,
    clearOpportunities,
    getOpportunitiesWithProducts,
    clearOpportunitiesWithProducts,
    deleteOpportunityWithProducts,
    clearAiOpportunities,
    getSummary,
    setRoute,
    //consts
    opportunityHeadings,
    opportunityHeadingsCustomerView,
    aiOpportunityHeadings,
    aiOpportunityHeadingsCustomerView,
  };
};
